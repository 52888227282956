import { environment as thisIsVegasEnvironment } from './environment.thisisvegas';
//this is the minimal registration version of This Is Vegas casino
// https://this-is-vegas.com/

export const environment = {
  ...thisIsVegasEnvironment,
  hasMinReg: true, //this is the only difference with original
};


